function onFetchInit(data) {
  let form = {}
  data.forEach((e, i) => {
    form['a' + i] = e.name
  })
  return {
    form
  }
}

function onSubmit({ detail, form }) {
  detail.forEach((e, i) => {
    e.name = form['a' + i]
  })
  return {
    list: detail
  }
}

export default {
  type: 'form',
  url: '/config/farmVipInfo/list',
  edit: '/config/farmVipInfo/updateBatch',
  onFetchInit,
  onSubmit,
  data: Array(7)
    .fill({})
    .map((e, i) => ({
      type: 'input',
      key: 'a' + i,
      name: '等级' + i
    }))
}
